<template>
  <section class="new-event">
    <div class="block md:flex justify-between">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">New User</h1>
    </div>

    <div class="mt-5">
      <div class="bg-white dark:bg-dark-bgSecondary border-t-2 border-grey-light dark:border-dark-textSecondary">
        <div class="relative flex flex-col min-w-0 break-words w-full">
          <div class="flex-auto dark:text-dark-textPrimary">
            <VeeForm v-slot="{ errors, handleSubmit }" :validation-schema="schema" as="div" class="flex flex-col font-fs-elliot-pro py-5">
              <form @submit="handleSubmit($event, onSubmit)">
                <div class="flex mb-6">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-name" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Name: </label>
                  </div>
                  <div class="w-2/3">
                    <Field
                      id="user-name"
                      v-model="payload.fullName"
                      name="fullName"
                      type="text"
                      :class="{
                        'text-danger border border-danger': errors.fullName,
                      }"
                      class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                    />
                    <ErrorMessage name="fullName" class="text-danger dark:text-danger text-base italic" />
                  </div>
                </div>

                <div class="flex mb-6">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-company" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Company: </label>
                  </div>
                  <div class="w-2/3">
                    <Field
                      id="user-company"
                      v-model="payload.company"
                      name="company"
                      type="text"
                      :class="{
                        'text-danger border border-danger': errors.company,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-xs md:text-base italic">
                      {{ errors.company }}
                    </p>
                  </div>
                </div>

                <div class="flex mb-6">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-email" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Email: </label>
                  </div>
                  <div class="w-2/3">
                    <Field
                      id="user-email"
                      v-model="payload.email"
                      name="email"
                      type="text"
                      :class="{
                        'text-danger border border-danger': errors.email,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-xs md:text-base italic">
                      {{ errors.email }}
                    </p>
                  </div>
                </div>

                <div class="flex mb-6">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-password" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Password: </label>
                  </div>
                  <div class="w-2/3">
                    <Field
                      id="user-password"
                      v-model="payload.password"
                      name="password"
                      type="password"
                      :class="{
                        'text-danger border border-danger': errors.password,
                      }"
                      class="
                        block
                        w-full
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-xs
                        md:text-lg
                        text-black-1
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        py-2
                        md:py-3
                        px-2
                        md:px-4
                        mb-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-xs md:text-base italic">
                      {{ errors.password }}
                    </p>
                  </div>
                </div>

                <div class="flex mb-6">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-access-level" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Access Level: </label>
                  </div>
                  <div class="w-2/3 md:w-1/6 mb-0">
                    <ComboBox
                      v-model="data.currentAccessLevel"
                      :options="[
                        { label: 'Admin', value: 'Admin' },
                        { label: 'User', value: 'User' },
                      ]"
                    />
                  </div>
                </div>
                <div class="flex mb-6" v-if="data.currentAccessLevel === 'User'">
                  <div class="w-1/2 md:w-1/3 lg:w-1/6">
                    <label for="user-access-level" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-semibold pt-2.5"> Event Access: </label>
                  </div>

                  <div class="w-2/3">
                    <div class="relative">
                      <input
                        v-if="data.eventList.length > 6"
                        v-model="data.searchEvent"
                        class="
                          block
                          w-full
                          appearance-none
                          bg-off-white-1
                          dark:bg-dark-bgPrimary
                          text-xs
                          md:text-lg
                          text-black-1
                          dark:text-dark-textPrimary
                          border border-grey-mid
                          focus:ring-magenta focus:border-magenta focus:outline-none
                          focus-visible:outline-none
                          rounded
                          py-2
                          md:py-3
                          px-2
                          md:px-4
                          mb-3
                        "
                      />
                      <span class="absolute top-2/4 transform -translate-y-2/4 right-4 font-bold cursor-pointer hover:scale-125 text-magenta" @click="data.searchEvent = ''"> ⤬ </span>
                    </div>
                    <div class="max-h-content-20vh overflow-y-auto flex flex-wrap">
                      <label class="self-start inline-flex items-center mt-3 cursor-pointer w-1/2" v-for="(event, index) in data.eventFiltered" :key="index">
                        <input v-model="data.eventSelected[event.id]" type="checkbox" class="form-checkbox h-5 w-5 text-magenta focus:ring-magenta" />
                        <span class="ml-2 text-gray-700 text-base lg:text-xl font-semibold text-black-1 dark:text-dark-textSecondary">
                          {{ event.name }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  class="button_large-Alternative absolute right-0 -bottom-4 md:-top-14 lg:-top-20 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
                >
                  Save
                </button>
              </form>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";

import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import { sleep } from "@Helpers/sleep";
import { postUser } from "@API/user/user-repository";
import ComboBox from "@/components/layouts/ComboBox";
import { map, filter, includes, toLower } from "lodash";

import { addUserEventAccess } from "@API/event/event-repository";
import { useTableData } from "@Libs/hooks/useDataTable";
import { empty } from "@/utils";

export default {
  name: "NewUser",
  components: {
    ComboBox,
    VeeForm,
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      currentAccessLevel: "User",
      eventSelected: {},
      eventList: [],
      eventFiltered: [],
      searchEvent: "",
    });

    const schema = Yup.object().shape({
      fullName: Yup.string().required("User name is required"),
      company: Yup.string().required("User company is required"),
      email: Yup.string().required("User email is required"),
      password: Yup.string().required("User password is required").min(8, "Password must be at least 8 characters"),
    });
    watch(
      () => data.eventList,
      value => (data.eventFiltered = value),
    );

    watch(
      () => data.searchEvent,
      value => {
        if (empty(value)) {
          data.eventFiltered = data.eventList;
        } else {
          data.eventFiltered = filter(data.eventList, event => includes(toLower(event.name), toLower(value)));
        }
      },
    );

    const doGetEvent = async () => {
      try {
        const { fetchEventsListDataTable } = useTableData();
        const eventList = await fetchEventsListDataTable("", "asc");
        data.eventList = eventList;
      } catch (error) {
        console.log({ error });
      }
    };

    onBeforeMount(async () => {
      try {
        store.dispatch("updateLoadingText", "Getting event's data");
        store.dispatch("toggleLoading");
        await doGetEvent();
      } catch (error) {
        console.log({ error });
      } finally {
        store.dispatch("closeLoading");
      }
    });

    return {
      data,
      schema,
    };
  },
  data() {
    return {
      payload: {},
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Creating new user");
        this.$store.dispatch("toggleLoading");
        const payload = {
          ...this.payload,
          accessLevel: this.data.currentAccessLevel,
        };

        const result = await postUser(payload);
        if (result.success && !empty(this.data.eventSelected)) {
          const { id } = result.data;
          const eventAccess = filter(
            map(this.data.eventSelected, (status, event) => status && event),
            item => item,
          );

          for (let i = 0; i < eventAccess.length; i++) {
            const event = eventAccess[i];
            await addUserEventAccess(event, {
              userName: id,
            });
          }
        }

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });

        if (result.success) {
          this.$router.push({
            name: "UserList",
          });
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.$store.dispatch("closeLoading");

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
